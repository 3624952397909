<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import defaultTemplate from "@/layout/defaultTemplate.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getMenuList } from "@/api/layout/layout";
import { getFilesList } from '@/api/common'

export default defineComponent({
  name: "App",
  components: {
    defaultTemplate,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    if (localStorage.getItem("accountData")) {
      store.replaceState(
        Object.assign(
          {},
          store.state,
          JSON.parse(localStorage.getItem("accountData"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("accountData", JSON.stringify(store.state));
    });

    const getMenu = () => {
      getMenuList().then((res) => {
        if (res) {
          if (res.listData) {
            store.commit("setMenuList", res.listData);
            const currentPath = route.path;
            const tempActive = res.listData.filter(
              (item) => currentPath.indexOf(item.url) === 0
            );
            if (tempActive && tempActive.length > 0) {
              store.commit("setActiveMenu", tempActive[0]);
            }
          }
        } else {
          store.commit("setMenuList", []);
        }
      });
    };

    const getImages = () => {
      getFilesList('/backImages', { baseURL: 'https://www.chenhfamily.com/api' }).then(res => {
        console.log(res)
        if (res.listData) {
          store.commit("setDefaultBackgroundImages", res.listData.map(item => item.url));
        }
      })
    }

    watch(
      () => route.path,
      (newPath) => {
        const tempActive = store.state.menuList.filter(
          (item) => newPath.indexOf(item.url) === 0
        );
        if (tempActive && tempActive.length > 0) {
          store.commit("setActiveMenu", tempActive[0]);
        }
      }
    );
    getMenu();
    getImages()

    return {};
  },
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  background-color: #f4f4f4;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    background: white;
  }

  &::-webkit-scrollbar-corner,
  /* 滚动条角落 */
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    /* 滚动条轨道 */
    background-color: rgba(180, 160, 120, 0.1);
    box-shadow: inset 0 0 1px rgba(180, 160, 120, 0.5);
  }
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

body {
  overflow-x: hidden !important;
}
</style>
