<template>
  <slot name="otherContent"></slot>
  <div class="bot-box">
    <div class="bot-images">
      <i class="iconfont icon-ego-blog images-icon cursor-pointer"></i>
    </div>
    <div class="bot-text">代码的灵魂尚在, 你今天敲它了吗?</div>

    <div class="menu">
      <!-- 微信 -->
      <i class="iconfont icon-weixin iconRightBoxStyle cursor-pointer"></i>
      <!-- QQ -->
      <i class="iconfont icon-qq iconRightBoxStyle cursor-pointer"></i>
      <!-- 微博 -->
      <i class="iconfont icon-weibo iconRightBoxStyle cursor-pointer" style="margin-right: 0"></i>
    </div>
    <div class="other-box" @click="goToNewPath">
      ©copyright JACKCHEN 2021-2022 皖ICP备18011829号-1
    </div>
  </div>
</template>
<script lang="ts" setup>
const goToNewPath = () => {
  window.location.href = "https://beian.miit.gov.cn";
};
</script>

<style lang="scss" scoped>
.bot-box {
  .bot-images {
    .images-icon {
      font-size: 80px;
    }
  }

  .bot-text {
    margin-top: 50px;
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2em;
  }

  .menu {
    margin-top: 20px;
    @extend%display-flex;
    justify-content: center;

    .iconRightBoxStyle {
      margin-right: 10px;
      color: black;
      font-size: 25px;
    }
  }

  .other-box {
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2em;
    cursor: pointer;
  }
}
</style>
