import VueMarkdownEditor from "@kangc/v-md-editor";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview-html.css";

import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Prism from "prismjs";
// 支持emoji 表情包插件
import createEmojiPlugin from "@kangc/v-md-editor/lib/plugins/emoji/index";
import "@kangc/v-md-editor/lib/plugins/emoji/emoji.css";
// 支持流程图mermaid
import createMermaidPlugin from "@kangc/v-md-editor/lib/plugins/mermaid/cdn";
import "@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css";

//支持todo任务列表
import createTodoListPlugin from "@kangc/v-md-editor/lib/plugins/todo-list/index";
import "@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css";
//支持代码行号
import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index";
//支持代码行高亮
import createHighlightLinesPlugin from "@kangc/v-md-editor/lib/plugins/highlight-lines/index";
import "@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css";
//支持快捷复制
import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index";
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css";
//内容定位
import createAlignPlugin from "@kangc/v-md-editor/lib/plugins/align";
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
})
  .use(createEmojiPlugin())
  .use(createTodoListPlugin())
  .use(createLineNumbertPlugin())
  .use(createHighlightLinesPlugin())
  .use(createCopyCodePlugin())
  .use(createAlignPlugin())
  .use(createMermaidPlugin());
VMdPreview.use(vuepressTheme, {
  Prism,
  config: {
    toc: {
      includeLevel: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  },
})
  .use(createEmojiPlugin())
  .use(createTodoListPlugin())
  .use(createLineNumbertPlugin())
  .use(createHighlightLinesPlugin())
  .use(createCopyCodePlugin())
  .use(createAlignPlugin())
  .use(createMermaidPlugin());
export { VueMarkdownEditor, VMdPreview };
