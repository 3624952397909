import fetch from "@/api/instance";

export const getFilesList = (config): Promise<any> =>
  fetch.get(`/getFilesList`, { ...config });

export const getByOthercCategory = (params: any): Promise<any> =>
  fetch.post("/getByOtherCategory", { ...params, loading: true });

export const createAccount = (params: any): Promise<any> =>
  fetch.post("/createAccount", { ...params, loading: true });

export const login = (params: any): Promise<any> =>
  fetch.post("/login", { ...params, loading: true });
