<template>
  <div class="default-container" :class="isDark ? 'theme-bgc-dark' : 'theme-bgc-white'">
    <el-container>
      <el-header class="template-header-style">
        <NavBar :isBgcTransparent="isBgcTransparent" :isShowLogo="isShowLogo" @triggerChangeTheme="handleChangeTheme">
          <el-input round placeholder="搜索" class="navbar-search">
            <template #suffix>
              <i class="iconfont icon-sousuo"></i>
            </template>
          </el-input>
        </NavBar>
      </el-header>

      <el-main class="no-padding" :class="isDark ? 'theme-bgc-dark' : 'theme-bgc-white'">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" v-if="$route.meta.keepAlive" @getScrollTop="getScrollTop" />
          </keep-alive>
          <component :is="Component" v-if="!$route.meta.keepAlive" @getScrollTop="getScrollTop" />
        </router-view>
      </el-main>
      <el-footer class="mt-50 customer-height" :class="isDark ? 'theme-bgc-dark' : 'theme-bgc-white'">
        <PageBot></PageBot>
      </el-footer>
    </el-container>
  </div>
</template>

<script lang="ts">
import NavBar from "@/components/NavBar.vue";
import PageBot from "@/components/PageBot.vue";

import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  ElContainer,
  ElHeader,
  ElMain,
  ElFooter,
  ElInput,
  ElDivider,
} from "element-plus";
export default defineComponent({
  name: "index",
  components: {
    NavBar,
    PageBot,
    ElContainer,
    ElHeader,
    ElMain,
    ElFooter,
    ElInput,
    ElDivider,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      isDark: computed(() => {
        return store.state.isDark;
      }),
      top: 0,
      isBgcTransparent: true, // 顶部nav 是否背景透明
      isShowLogo: false, // 顶部 nav log 是否显示
      blogList: [],
    });

    const computedIsBgcTransparent = () => {
      // 判断当前路由有没有meta标签, meta标签上时候包含头部nav相关配置
      if (router.currentRoute.value && router.currentRoute.value.meta) {
        if ("isBgcTransparent" in router.currentRoute.value.meta) {
          data["isBgcTransparent"] =
            router.currentRoute.value.meta.isBgcTransparent;
        }
        if ("isShowLogo" in router.currentRoute.value.meta) {
          data["isShowLogo"] = router.currentRoute.value.meta.isShowLogo;
        }
      }
    };
    computedIsBgcTransparent();

    const getScrollTop = (top) => {
      data.top = top;
      if (!data.isDark) {
        if (data.top > 150) {
          data.isBgcTransparent = false;
          data.isShowLogo = true;
        } else {
          data.isBgcTransparent = true;
          data.isShowLogo = false;
        }
      }
    };

    const handleChangeTheme = () => {
      if (data.isDark) {
        if (data.top > 150) {
          data.isBgcTransparent = false;
          data.isShowLogo = true;
        } else {
          data.isBgcTransparent = true;
          data.isShowLogo = false;
        }
      } else {
        data.isBgcTransparent = false;
        data.isShowLogo = true;
      }

      store.commit("setIsDark", !store.state.isDark);
    };
    const pageData = toRefs(data);
    onMounted(() => {
      if (data.isDark) {
        data.isBgcTransparent = false;
        data.isShowLogo = true;
      }
    });
    return {
      ...pageData,
      handleChangeTheme,
      getScrollTop,
    };
  },
});
</script>
<style lang="scss" scoped>
.navbar-search {
  max-width: 400px;
  margin-left: 20px;
}

.mt-50 {
  padding-top: 50px;
}



.template-header-style {
  height: 80px;
}

.bacckground-opacity {
  opacity: 1;
}

.nav-theme-style {
  font-size: 36px;
  margin-right: 20px;
}

.color-white {
  color: white;
}

.default-container {
  height: 100vh;
}

.customer-height {
  height: 100%;
}
</style>
