// 安装 vuex
// cnpm i vuex@next --save

import { createStore } from "vuex";
export default createStore({
  state: {
    account: {},
    rootImageDir: "https://oss.chenhfamily.com/", // 项目图片根路径
    isDark: false, // 是否是黑暗背景
    defaultTemplateScrollHeight: 0, // 默认背景下的滚动高度
    menuList: [], // 菜单列表
    activeMenu: {}, // 当前点击的菜单
    defaultImgUrl: "https://oss.chenhfamily.com/default/default.gif",
    defaultBackgroundImage:
      "https://oss.chenhfamily.com/default/24110307_23.jpeg",
    defaultBackgroundImages: [],
  },
  getters: {
    generationRandom: (state) => (length) => {
      if (length === 1) {
        const random = Math.floor(
          Math.random() * state.defaultBackgroundImages.length
        );
        return (
          state.defaultBackgroundImages && state.defaultBackgroundImages[random]
        );
      } else {
        let shuffled = state.defaultBackgroundImages.slice(0),
          i = state.defaultBackgroundImages.length,
          min = i - length,
          temp,
          index;
        while (i-- > min) {
          index = Math.floor((i + 1) * Math.random());
          temp = shuffled[index];
          shuffled[index] = shuffled[i];
          shuffled[i] = temp;
        }
        return shuffled.slice(min);
      }
    },
  },
  mutations: {
    setAccountData(state, value) {
      state.account = value;
    },
    setIsDark(state, value) {
      state.isDark = value;
    },
    setDefaultTemplateScrollHeight(state, value) {
      state.defaultTemplateScrollHeight = value;
    },
    setMenuList(state, value) {
      state.menuList = value;
    },
    setActiveMenu(state, value) {
      state.activeMenu = value;
    },
    setDefaultBackgroundImages(state, value) {
      state.defaultBackgroundImages = value;
    },
  },
  actions: {},
  modules: {},
});
