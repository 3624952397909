// 安装 路由
// cnpm i vue-router@next --save
import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/index",
    },
    {
      path: "/",
      name: "index",
      component: () => import("@/layout/defaultTemplate.vue"),

      children: [
        {
          path: "index",
          name: "index",
          component: () => import("@/pages/index.vue"),
          // meta: {
          //   keepAlive: true,
          // },
        },
        {
          path: "blog",
          name: "blog",
          component: () => import("@/pages/Blog/index.vue"),
        },
        {
          path: "study",
          name: "study",
          component: () => import("@/pages/Study/study.vue"),
        },
        {
          path: "suiji",
          name: "suiji",
          component: () => import("@/pages/SuiJi/suiji.vue"),
        },
      ],
    },

    {
      path: "/detail",
      name: "detail",
      component: () => import("@/layout/detailTemplate.vue"),
      children: [
        {
          path: "page/:id",
          name: "detail",
          component: () => import("@/pages/BlogDetail/index.vue"),
        },
      ],
    },
  ],
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
