import { createApp } from "vue";
import App from "@/App.vue";
import router from "./router/router";
import { setDomFontSize } from "./utils/dom";
import "element-plus/dist/index.css";
import { Loading } from "element-plus/es/components/loading/src/service";

import { VueMarkdownEditor, VMdPreview } from "./plugins/vmarkeditor";

const app = createApp(App);
setDomFontSize();
app.directive("loading", Loading);

import store from "./store/store";

app.use(VueMarkdownEditor).use(VMdPreview).use(router).use(store).mount("#app");
export default app;
