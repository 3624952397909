<template>
  <div class="nav-container" :class="'theme-bgc-dark'">
    <div class="nav-center">
      <div class="nav-center-items">
        <span class="website-title" :class="'theme-color-white'">{{
    commonText }}</span>
        <template v-if="isShowMenu">
          <span v-for="(menu, idx) in menuList" :key="'menu' + idx" class="menu-item cursor-pointer" :class="[
    activeObj._id === menu._id ? 'active-menu' : '',
    'theme-color-white',
  ]" @click="handleClickTo(menu)">{{ menu.name }}</span>
        </template>

      </div>
    </div>

    <!-- 当前登录用户 -->
    <div class="user-login-info display-center">
      <slot></slot>
      <el-switch v-model="isDark" @change="handleChangeTheme" :active-icon="Moon" :inactive-icon="Sunny" size="large"
        inline-prompt class="mx-20" style="--el-switch-on-color: black; --el-switch-off-color: black" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  ref,
  computed,
} from "vue";
import { Sunny, Moon } from '@element-plus/icons-vue'
import defaultText from "@/utils/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  ElSwitch
} from "element-plus";
const props = defineProps({
  isBgcTransparent: {
    // nav背景是否透明
    type: Boolean,
    default() {
      return false;
    },
  },
  // 是否显示左侧logo
  isShowLogo: {
    type: Boolean,
    default() {
      return true;
    },
  },
  isShowMenu: {
    type: Boolean,
    default() {
      return true;
    },
  }
})

const emits = defineEmits(['triggerChangeTheme'])

const store = useStore();
const activeObj = computed(() => {
  return store.state.activeMenu;
});
const avatar = computed(() => {
  return store.state.account.avatar
})

const menuList = computed(() => {
  return store.state.menuList;
})
const commonText = ref(defaultText.author)
const isDark = computed(() => {
  return store.state.isDark;
})
const userInfo = computed(() => {
  return store.state.account;
})
const menuTextIsBlack = computed(() => {
  let flag = false;
  // 只有当前主题不是黑暗主题时,才会有如下判断
  // 只有 nav 不透明时(也就是为白色背景,显示时),才是黑色字体,其余都是白色字体
  if (!isDark.value && !props.isBgcTransparent) {
    flag = true;
  }
  return flag;
})

const router = useRouter();
const handleClickTo = (menu) => {
  if (menu.url) {
    store.commit("setActiveMenu", menu);
    router.push(menu.url);
  }
};
const logout = () => {
  store.commit("setAccountData", {});
};
const handleChangeTheme = (value) => {
  emits('triggerChangeTheme', value)
};



</script>

<style scoped lang="scss">
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all ease 0.5s;

  .nav-center {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 80px;

    // .nav-left-title {
    //   font-size: 17.5px;
    //   font-weight: bold;

    //   .nav-left-img-style {
    //     width: 140px;
    //     height: 100px;
    //   }
    // }

    .nav-center-items {
      width: 100%;
      display: flex;
      align-items: center;
      height: 24px;

      .website-title {
        font-size: 17.5px;
        font-weight: bold;
        margin-right: 80px;
      }



      .menu-item {
        height: 24px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        font-size: 16px;
        line-height: 24px;
        margin-left: 32px;
      }

      .active-menu {
        border-radius: 20px;
        color: #f75454;
      }
    }
  }

  .user-login-info {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);

    .bg-month-dark {

      .nav-theme-style {
        font-size: 30px;
      }
    }

    .user-name-class {
      font-size: 16px;
      margin-left: 5px;
      font-weight: bold;
      cursor: pointer;
      color: white;
    }

    .dropdown-text {
      color: var(--el-color-primary);
    }
  }
}
</style>
